import { PracticeQueryParams } from 'constants/application-constants'

import { useCallback, useEffect, useState } from 'react'

import { FileCheckIcon } from 'assets/Icons'
import clsx from 'clsx'
import {
  ActionBar,
  Background,
  Button,
  IconContainer,
  Tooltip,
  Typography,
} from 'components/common'
import { addToast } from 'components/features/Other/api'
import { copyToClipboard } from 'helpers'
import {
  useAppDispatch,
  useIsBelowBreakpoint,
  useFrontOfficeCheck,
} from 'hooks'
import { t } from 'i18next'
import { ArrowRight, Info, Link, MessageCircle } from 'react-feather'
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import ApprovalSidebarPage from './ApprovalSidebarPage'
import CommentsSidebarPage from './CommentsSidebarPage'
import style from './PracticeSidebar.module.scss'
import SuccessIcon from '../../../../../assets/Icons/SuccessIcon'
import { PracticeSidebarPages } from '../../types'

const sidebarPages: PracticeSidebarPages[] = ['comments', 'approval']

const PracticeSidebar = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = useState<PracticeSidebarPages | 'sidebar' | null>(
    null
  )
  const [isCopying, setIsCopying] = useState(false)
  const isFrontOffice = useFrontOfficeCheck()
  const { id: practiceId = '' } = useParams()
  const [searchParams] = useSearchParams()
  const isTabPort = useIsBelowBreakpoint('tabPort')

  const isPublishedRevision =
    searchParams.get(PracticeQueryParams.REVISION_STATUS) ===
    ('published' as const)

  const locationHash = location.hash?.split('#')[1]

  const handlePageChange = useCallback(
    (page?: string) => {
      if (!page) {
        setPage(null)
      }

      const newPage = sidebarPages.find((item) => item === page)

      if (newPage === 'approval' && (isFrontOffice || isPublishedRevision)) {
        setPage(null)
      }

      if (newPage) {
        setPage(newPage)
      }

      if (page === 'sidebar') {
        setPage('sidebar')
      }
    },
    [isFrontOffice, isPublishedRevision]
  )

  useEffect(() => {
    handlePageChange(locationHash)
  }, [handlePageChange, locationHash])

  const toggleSidebar = (hash: string | null) => {
    navigate(
      {
        ...location,
        hash: hash ?? '',
      },
      {
        replace: true,
      }
    )
  }

  const handleActionClick = (action: string | null) => () => {
    if (action === null || action === 'comments' || action === 'approval') {
      toggleSidebar(action)
    }

    if (action === 'details') {
      navigate(
        {
          ...location,
          pathname: 'details',
        },
        { replace: true }
      )
    }
    if (action === 'link') {
      copyToClipboard(window.location.href)
      setIsCopying(true)
      dispatch(addToast({ color: 'success', content: t('practice.linkCopy') }))

      setTimeout(() => {
        setIsCopying(false)
      }, 3000)
    }
  }

  return (
    <>
      <div
        className={clsx(style.sidebarMobile, {
          [style.hide]: !page,
        })}
      >
        {page === 'sidebar' && (
          <Background
            open={isTabPort}
            color="tinted"
            onClick={handleActionClick(null)}
          >
            <div
              className={style.sheet}
              onClick={(evt) => evt.stopPropagation()}
            >
              <NavLink
                to={{ ...location, pathname: 'details', hash: '' }}
                className={style.link}
              >
                <IconContainer className={style.icon} icon={<ArrowRight />} />
                <Typography>{t('practiceSidebar.detailsTooltip')}</Typography>
              </NavLink>
              <NavLink
                to={{ ...location, hash: 'comments' }}
                className={style.link}
              >
                <IconContainer
                  className={style.icon}
                  icon={<MessageCircle />}
                />
                <Typography>{t('practiceSidebar.commentsButton')}</Typography>
              </NavLink>
              <NavLink
                to={{
                  ...location,
                  hash: '',
                }}
                className={style.link}
                onClick={handleActionClick('link')}
              >
                <IconContainer className={style.icon} icon={<Link />} />
                <Typography>{t('practiceSidebar.copyLinkButton')}</Typography>
              </NavLink>
            </div>
          </Background>
        )}
        {page === 'comments' && (
          <Background open={isTabPort}>
            <div className={style.comments}>
              <CommentsSidebarPage onActionClick={handleActionClick} />
            </div>
          </Background>
        )}
      </div>
      <ActionBar isOpen={!!page} className={style.actionBar}>
        <ActionBar.Actions className={style.actions}>
          <Tooltip
            content={t('practiceSidebar.commentsTooltip')}
            position="left"
            color="dark"
          >
            <Button
              icon={<MessageCircle />}
              onClick={handleActionClick('comments')}
              aria-label={t('practiceSidebar.commentsButton') ?? ''}
              variant="subtle"
              size="large"
              as="button"
            />
          </Tooltip>
          {!isFrontOffice && !isPublishedRevision && (
            <Tooltip
              content={t('practiceSidebar.approvalsTooltip')}
              position="left"
              color="dark"
            >
              <Button
                icon={<FileCheckIcon />}
                onClick={handleActionClick('approval')}
                aria-label={t('practiceSidebar.approvalsButton') ?? ''}
                variant="subtle"
                size="large"
                as="button"
              />
            </Tooltip>
          )}
          <Tooltip
            content={
              isCopying
                ? t('practiceSidebar.linkCopiedTooltip')
                : t('practiceSidebar.copyLinkTooltip')
            }
            position="left"
            color="dark"
          >
            <Button
              icon={isCopying ? <SuccessIcon /> : <Link />}
              onClick={handleActionClick('link')}
              aria-label={t('practiceSidebar.copyLinkButton') ?? ''}
              variant="subtle"
              size="large"
              as="button"
            />
          </Tooltip>
          <Tooltip
            content={t('practiceSidebar.detailsTooltip')}
            position="left"
            color="dark"
          >
            <Button
              icon={<Info />}
              onClick={handleActionClick('details')}
              aria-label={t('practiceSidebar.detailsButton') ?? ''}
              variant="subtle"
              size="large"
              as="button"
            />
          </Tooltip>
        </ActionBar.Actions>
        <ActionBar.Pages
          showBackButton
          className={style.pages}
          onBackClick={handleActionClick(null)}
        >
          {page === 'comments' && (
            <ActionBar.Page className={style.page}>
              <CommentsSidebarPage onActionClick={handleActionClick} />
            </ActionBar.Page>
          )}
          {page === 'approval' && !isFrontOffice && !isPublishedRevision && (
            <ActionBar.Page className={style.page}>
              <ApprovalSidebarPage
                practiceId={practiceId}
                onActionClick={handleActionClick}
              />
            </ActionBar.Page>
          )}
        </ActionBar.Pages>
      </ActionBar>
    </>
  )
}

export default PracticeSidebar
