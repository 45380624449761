import { t } from 'i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './EmptyDocumentsList.module.scss'
import Plus from '../../../../../assets/Icons/Plus'
import { Button, Card, Section, Typography } from '../../../../common'
import DocumentIcon from '../../../Other/components/DocumentIcon/DocumentIcon'

const EmptyDocumentsList = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleAddClick = () => {
    navigate({
      ...location,
      pathname: 'upload',
    })
  }

  return (
    <Section>
      <Card>
        <Card.Body className={styles.body}>
          <DocumentIcon type={'document'} />
          <div className={styles.wrapper}>
            <Typography
              element={'h3'}
              fontWeight={'bold'}
              size={'x-large'}
              className={styles.row}
            >
              {t('practice.empty')}
            </Typography>
            <Typography color={'body'} className={styles.row}>
              {t('practice.emptyText')}
              <br />
              {t('practice.addContent')}
            </Typography>
          </div>
          <div className={styles.buttonRow}>
            <Button
              variant={'secondary'}
              size={'small'}
              icon={<Plus />}
              onClick={handleAddClick}
            >
              {t('common.add')}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Section>
  )
}

export default EmptyDocumentsList
