import { Mail } from 'assets/Icons'
import { Avatar, Button, Card, Typography } from 'components/common'
import { truncateWithEllipses } from 'helpers'
import { t } from 'i18next'
import { ExternalLink } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { Metadata, User } from 'types'

import styles from './MetadataDetailCard.module.scss'
import ReworkPracticeModal from './ReworkPracticeModal'
import { OfficeName } from '../../../../../constants/application-constants'
import { useFrontOfficeCheck, useIsBelowBreakpoint } from '../../../../../hooks'
import { Practice } from '../../../../../redux-store/models'
import WarningAccordion from '../../../../common/Accordion/WarningAccordion'
import { userApi } from '../../../User/api'

interface PracticeDetailCardProps {
  author: User | null
  metadata: Metadata
  practice: Practice
  practices: { id: string; name: string }[]
  isModalOpen: boolean
  toggleModal: () => void
}

const MetadataDetailCard = ({
  metadata,
  author,
  practice,
  practices,
  isModalOpen,
  toggleModal,
}: PracticeDetailCardProps): JSX.Element => {
  const isTabPort = useIsBelowBreakpoint('tabPort')
  const isFrontOffice = useFrontOfficeCheck()

  const cardColumnData = [
    {
      label: t('metadata.description'),
      value: metadata.description ?? '-',
      type: 'string',
    },
    {
      label: t('metadata.internalReferences'),
      type:
        metadata.internal_references && metadata.internal_references?.length > 0
          ? 'list'
          : 'string',
      value:
        metadata.internal_references && metadata.internal_references?.length > 0
          ? metadata.internal_references.map((internal_reference) => {
              const filtered = practices.filter(
                (p) => p.id === internal_reference
              )
              if (filtered.length > 0) {
                return (
                  <div
                    key={`connected-gl-${internal_reference}`}
                    className={styles.listItem}
                  >
                    <a
                      href={`/${
                        isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
                      }/practices/${internal_reference}`}
                      title={filtered[0].name}
                    >
                      {truncateWithEllipses(filtered[0].name, 40)}
                    </a>
                  </div>
                )
              }
              return <></>
            })
          : '-',
    },
    {
      label: t('metadata.targetGroup'),
      value: metadata.target_group ?? '-',
      type: 'string',
    },
    {
      label: t('metadata.externalReferences'),
      type:
        metadata.external_references && metadata.external_references?.length > 0
          ? 'list'
          : 'string',
      value:
        metadata.external_references && metadata.external_references?.length > 0
          ? metadata.external_references.map((v, i) => {
              if (!v.value) return <>-</>
              return (
                <div key={`extRef-${i}`} className={styles.listItem}>
                  {v.value.startsWith('http') && (
                    <a
                      href={v.value}
                      title={v.name ? v.name : v.value}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLink size="14" className={styles.icon} />
                      {truncateWithEllipses(v.name ? v.name : v.value, 35)}
                    </a>
                  )}
                  {!v.value.startsWith('http') && (
                    <>{truncateWithEllipses(v.value, 40)}</>
                  )}
                </div>
              )
            })
          : '-',
    },
  ]

  const location = useLocation()
  const navigate = useNavigate()

  const httpGetUser = userApi.useGetUserByIdQuery(
    metadata?.contact_user?.id ?? '',
    {
      skip: !metadata.contact_user.id,
    }
  )

  const handleShowDetails = () => {
    navigate(
      {
        ...location,
        pathname: 'details',
      },
      { replace: true }
    )
  }

  let displayedContact = httpGetUser.isSuccess ? httpGetUser.data.data : author

  return (
    <>
      <ReworkPracticeModal
        practice={practice}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
      <Card data-testid="metadata-details-card" className={styles.root}>
        {isFrontOffice && isTabPort && practice.is_outdated && (
          <WarningAccordion />
        )}
        <Card.Body className={styles.body}>
          {cardColumnData.map(({ label, value, type }) => (
            <div key={label + value} className={styles.col}>
              <Typography
                color="label"
                size="small"
                fontWeight="medium"
                element="h4"
              >
                {label}
              </Typography>
              {type === 'string' && (
                <Typography
                  size="small"
                  element="p"
                  className={styles.colContent}
                >
                  {value}
                </Typography>
              )}
              {type === 'list' && <>{value}</>}
            </div>
          ))}
        </Card.Body>
        <Card.Footer className={styles.footer}>
          <div className={styles.author}>
            {!!displayedContact ? (
              <>
                <Avatar
                  src={displayedContact?.avatar_public_path}
                  content={displayedContact?.initials ?? ''}
                />

                <div className={styles.authorDescription}>
                  <Typography fontWeight="medium">
                    {displayedContact?.name}
                  </Typography>
                  <Typography color="label">{t('practice.author')}</Typography>
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
          <div className={styles.actions}>
            {!!displayedContact?.email && (
              <Button
                to={`mailto:${displayedContact?.email}`}
                as="link"
                size="small"
                variant="subtle"
                icon={<Mail />}
              >
                {t('practice.toContact')}
              </Button>
            )}
            <Button
              onClick={handleShowDetails}
              size="small"
              variant="secondary"
            >
              {t('practice.practiceDetails')}
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  )
}

export default MetadataDetailCard
