import React from 'react'

const SuccessIcon = () => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.73523L5.24264 9.97787L13.7279 1.49259"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default SuccessIcon
